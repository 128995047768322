* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@keyframes flashBorder {
  0%, 100% { border-color: transparent; }
  50% { border-color: #b91c1c; }
}

.normalBackground {
  background-color: white; /* or your normal background color */
}

.redBackground {
  background-color: red;
}